import React, { Fragment, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from '../../reusecore/src/elements/Box';
import Text from '../../reusecore/src/elements/Text';
import TextAlt from '../../reusecore/src/elements/Text/text';
import CustomText from '../../reusecore/src/elements/Text/text';
import Heading from '../../reusecore/src/elements/Heading';
import Input from '../Input';
import CheckBox from '../../reusecore/src/elements/Checkbox/index';
import Button from '../../reusecore/src/elements/Button';
import Image from '../../reusecore/src/elements/Image';
import LoginModalWrapper from './loginModal.style';
import 'rc-tabs/assets/index.css';
import LogoImage from '../../assets/image/logo.png';
import LoginImage from '../../assets/image/login-bg.jpg';
import { Formik, useFormik } from 'formik';
import { LoginFormParameters } from '../LoginForm';
import Select from '../Select';
import axios from 'axios';
import firebase from 'firebase';
import { Link, navigate } from 'gatsby';
import * as Yup from 'yup';
import { theme } from '../../theme';

export interface LoginValues {
  email: string;
  password: string;
}

interface RegistrationStageOneValues {
  fullName: string;
  email: string;
  password: string;
}

const RegistrationStageOneValidationSchema = Yup.object().shape<
  RegistrationStageOneValues
>({
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Please fill out this section.'),
  password: Yup.string()
    .min(8, 'Password must contain at least eight characters.') //  Must be minimum of length eight.
    .matches(/[A-Za-z]/, 'Password must contain letter.') // Must contain letter.
    .matches(/[0-9]/, 'Password must contain number.') // Must contain number.
    .required('Please fill out this section.'),
  fullName: Yup.string().required('Please fill out this section.'),
});

interface RegistrationStageTwoValues {
  age: string;
  postcode: string;
  country: string;
  gender: string;
  otherGender: string;
  sexuality: string;
  otherSexuality: string;
  agreeTerms: boolean;
  agreeEmail: boolean;
}

const RegistrationStageTwoValidationSchema = Yup.object().shape<
  RegistrationStageTwoValues
>({
  postcode: Yup.string(),
  age: Yup.string().required('Please fill out this section.'),
  gender: Yup.string()
    .ensure()
    .required('Please fill out this section.'),
  sexuality: Yup.string()
    .ensure()
    .required('Please fill out this section.'),
  country: Yup.string()
    .ensure()
    .required('Please fill out this section.'),
  otherSexuality: Yup.string(),
  otherGender: Yup.string(),
  agreeTerms: Yup.boolean().test(
    'terms-accepted',
    'You need to agree to our terms to register.',
    value => value
  ),
  agreeEmail: Yup.boolean(),
});

export interface RegistrationValues
  extends RegistrationStageOneValues,
    RegistrationStageTwoValues {}

export const OTHER_OPTION = 'Other (please specify)';

const SEXUALITIES: string[] = [
  'Gay',
  'Lesbian',
  'Bisexual',
  'Queer',
  'Pansexual',
  'Asexual',
  'Heterosexual',
  OTHER_OPTION,
  'Prefer not to say',
];

const GENDERS: string[] = [
  'Male',
  'Female',
  'Non-binary',
  'Transgender Male',
  'Transgender Female',
  'Genderqueer',
  OTHER_OPTION,
  'Prefer not to say',
];

const NON_UK_POSTCODE = 'IV27 4JG';

const AuthModal = ({
  row,
  col,
  btnStyle,
  logoStyle,
  titleStyle,
  contentWrapper,
  outlineBtnStyle,
  descriptionStyle,
  googleButtonStyle,
}) => {
  const LoginButtonGroup = () => (
    <Fragment>
      <Button className="default" title="LOGIN" {...btnStyle} />
      <Button
        title="Forget Password"
        variant="textButton"
        {...outlineBtnStyle}
      />
    </Fragment>
  );

  const [loginError, setLoginError] = useState<string>(null);

  const [stageOneValues, setStageOneValues] = useState<{
    fullName: string;
    email: string;
    password: string;
  }>(null);

  const stageReducer = (state, action) => {
    switch (action) {
      case 'increment':
        return state + 1;
      case 'decrement':
        return state - 1;
      default:
        throw new Error();
    }
  };

  // @TODO: Refactor handleLogin to async.
  const handleLogin = (values: LoginValues): void => {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then<void>(() => {
        firebase
          .auth()
          .signInWithEmailAndPassword(values.email, values.password)
          .then<void>(() => {
            navigate('/user');
          })
          .catch(e => {
            console.error(e);
            if (e.code === 'auth/user-not-found') {
              setLoginError(
                "We couldn't find an account associated with this email address."
              );
            } else if (e.code === 'auth/invalid-password') {
              setLoginError('Invalid password for this account.');
            }
          });
      });
  };

  const handleRegister = async (values: RegistrationValues): Promise<void> => {
    try {
      setLoginError(null);
      const postcodeQuery =
        values.country !== 'Non-UK' ? values.postcode : NON_UK_POSTCODE;
      const postcodeResponse = await axios.get<{
        result: { latitude: number; longitude: number };
      }>(
        `https://api.postcodes.io/postcodes/${postcodeQuery.replace(/\s/g, '')}`
      );
      if (
        postcodeResponse.status !== 200 ||
        !postcodeResponse.data.result.latitude
      ) {
        throw new Error('Postcode could not be found.');
      }
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const creds = await firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password);
      await creds.user.sendEmailVerification();
      await firebase
        .firestore()
        .collection('users')
        .doc(creds.user.uid)
        .set({
          email: creds.user.email,
          fullName: values.fullName,
          lat: postcodeResponse.data.result.latitude,
          long: postcodeResponse.data.result.longitude,
          postcode:
            values.country !== 'Non-UK' ? values.postcode : NON_UK_POSTCODE,
          age: parseInt(values.age),
          sexuality: values.sexuality,
          otherSexuality:
            values.sexuality === OTHER_OPTION ? values.otherSexuality : null,
          gender: values.gender,
          otherGender:
            values.gender === OTHER_OPTION ? values.otherGender : null,
          emailConsent: values.agreeEmail,
        });
      await navigate('/user');
    } catch (e) {
      console.error(e);
    }
  };

  const [stage, stageDispatch] = useReducer(stageReducer, 1);

  return (
    <LoginModalWrapper>
      <Box className="row" {...row}>
        <Box className="col imageCol" {...col}>
          <Image className="patternImage" src={LoginImage} alt="Login Banner" />
        </Box>
        <Box className="col tabCol" {...col}>
          <Box {...contentWrapper}>
            <Image src={LogoImage} {...logoStyle} alt="Logo" />
            <Tabs
              defaultActiveKey="registerForm"
              renderTabBar={() => <ScrollableInkTabBar />}
              renderTabContent={() => <TabContent />}
            >
              <TabPane tab="REGISTER" key="registerForm">
                {stage <= 1 && (
                  <>
                    <Heading
                      content="To proceed please create an account, it will only take a minute"
                      {...titleStyle}
                      fontSize={4}
                      lineHeight="normal"
                    />
                    <Text
                      content="We ask for this so that we can save your test and match results
                      on your profile so you can come back and review or retake the tests as often
                      as you want. We will never sell your data or spam your inbox, we promise."
                      {...descriptionStyle}
                    />
                    <Formik
                      initialValues={{
                        fullName: '',
                        email: '',
                        password: '',
                        agreeTerms: false,
                        agreeEmail: false,
                      }}
                      onSubmit={values => {
                        setStageOneValues(values);
                        stageDispatch('increment');
                      }}
                      validationSchema={RegistrationStageOneValidationSchema}
                      validateOnBlur={true}
                    >
                      {formikProps => {
                        return (
                          <>
                            <Input
                              isMaterial
                              label="Full Name"
                              name="fullName"
                              value={formikProps.values.fullName}
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                            />
                            {!!formikProps.touched.fullName &&
                              !!formikProps.errors.fullName && (
                                <CustomText
                                  fontFamily="body"
                                  fontSize={11}
                                  color="tomato"
                                  mb={2}
                                  mt="-1.5rem"
                                >
                                  {formikProps.errors.fullName}
                                </CustomText>
                              )}
                            <Input
                              isMaterial
                              label="Email"
                              name="email"
                              value={formikProps.values.email}
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                            />
                            {!!formikProps.touched.email &&
                              !!formikProps.errors.email && (
                                <CustomText
                                  fontFamily="body"
                                  fontSize={11}
                                  color="tomato"
                                  mb={2}
                                  mt="-1.5rem"
                                >
                                  {formikProps.errors.email}
                                </CustomText>
                              )}
                            <Input
                              inputType="password"
                              isMaterial
                              label="Password"
                              name="password"
                              value={formikProps.values.password}
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                            />
                            <CustomText
                              fontFamily="body"
                              fontSize={11}
                              mb={4}
                              mt="-1.5rem"
                            >
                              Password must be eight characters long, containing
                              both letters and numbers.
                            </CustomText>
                            {!!formikProps.touched.password &&
                              !!formikProps.errors.password && (
                                <CustomText
                                  fontFamily="body"
                                  fontSize={11}
                                  color="tomato"
                                  mb={2}
                                  mt="-1.5rem"
                                >
                                  {formikProps.errors.password}
                                </CustomText>
                              )}
                            <div>
                              <Button
                                mt={4}
                                className="default"
                                title="NEXT"
                                {...btnStyle}
                                onClick={formikProps.handleSubmit}
                                disabled={formikProps.isSubmitting}
                              />
                            </div>
                          </>
                        );
                      }}
                    </Formik>
                  </>
                )}
                {stage === 2 && (
                  <Box pt={5}>
                    <Formik
                      initialValues={{
                        age: '',
                        postcode: '',
                        gender: null,
                        otherGender: '',
                        sexuality: null,
                        otherSexuality: '',
                        country: null,
                        agreeTerms: false,
                        agreeEmail: false,
                      }}
                      onSubmit={async (stageTwoValues, formikHelpers) => {
                        await handleRegister({
                          ...stageOneValues,
                          ...stageTwoValues,
                        });
                      }}
                      validationSchema={RegistrationStageTwoValidationSchema}
                    >
                      {formikProps => {
                        return (
                          <>
                            <Input
                              isMaterial
                              label="Age"
                              name="age"
                              value={formikProps.values.age}
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                            />
                            {!!formikProps.touched.age &&
                              !!formikProps.errors.age && (
                                <CustomText
                                  fontFamily="body"
                                  fontSize={11}
                                  color="tomato"
                                  mb={2}
                                  mt="-1.5rem"
                                >
                                  {formikProps.errors.age}
                                </CustomText>
                              )}
                            <Select
                              name="country"
                              options={[
                                { value: 'UK', label: 'UK' },
                                { value: 'Non-UK', label: 'Non-UK' },
                              ]}
                              placeholder="Country"
                            />
                            {!!formikProps.touched.country &&
                              !!formikProps.errors.country && (
                                <CustomText
                                  fontFamily="body"
                                  fontSize={11}
                                  color="tomato"
                                  mb={2}
                                  mt="-1.5rem"
                                >
                                  {formikProps.errors.country}
                                </CustomText>
                              )}
                            {formikProps.values.country !== 'Non-UK' && (
                              <Input
                                isMaterial
                                label="Postcode"
                                name="postcode"
                                value={formikProps.values.postcode}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                              />
                            )}
                            <CustomText
                              fontFamily="body"
                              fontSize={11}
                              mb={4}
                              mt="-1.5rem"
                            >
                              Your postcode is only used for the therapist
                              matching questionnaire.
                            </CustomText>
                            {!!formikProps.touched.postcode &&
                              !formikProps.values.postcode &&
                              formikProps.values.country !== 'Non-UK' && (
                                <CustomText
                                  fontFamily="body"
                                  fontSize={11}
                                  color="tomato"
                                  mb={3}
                                  mt="-1.5rem"
                                >
                                  Please fill out this section.
                                </CustomText>
                              )}
                            <Select
                              name="gender"
                              options={GENDERS.map<{
                                value: string;
                                label: string;
                              }>(x => ({ label: x, value: x }))}
                              placeholder="Gender"
                            />
                            {!!formikProps.touched.gender &&
                              !!formikProps.errors.gender && (
                                <CustomText
                                  fontFamily="body"
                                  fontSize={11}
                                  color="tomato"
                                  mb={2}
                                  mt="-1.5rem"
                                >
                                  {formikProps.errors.gender}
                                </CustomText>
                              )}
                            {formikProps.values.gender === OTHER_OPTION && (
                              <Input
                                isMaterial
                                label="Please specify gender"
                                name="otherGender"
                                value={formikProps.values.otherGender}
                                onChange={formikProps.handleChange}
                              />
                            )}
                            <Select
                              name="sexuality"
                              options={SEXUALITIES.map<{
                                value: string;
                                label: string;
                              }>(x => ({ label: x, value: x }))}
                              placeholder="Sexuality"
                            />
                            {!!formikProps.touched.sexuality &&
                              !!formikProps.errors.sexuality && (
                                <CustomText
                                  fontFamily="body"
                                  fontSize={11}
                                  color="tomato"
                                  mb={2}
                                  mt="-1.5rem"
                                >
                                  {formikProps.errors.sexuality}
                                </CustomText>
                              )}
                            {formikProps.values.sexuality === OTHER_OPTION && (
                              <Input
                                isMaterial
                                label="Please specify sexuality"
                                name="otherSexuality"
                                value={formikProps.values.otherSexuality}
                                onChange={formikProps.handleChange}
                              />
                            )}
                            <Box flexBox alignItems="center" pb={3}>
                              <input
                                type="checkbox"
                                name="agreeEmail"
                                checked={formikProps.values.agreeEmail}
                                onChange={formikProps.handleChange}
                              />
                              <TextAlt
                                m={0}
                                pl={2}
                                fontFamily="body"
                                fontSize={11}
                                color="muted"
                              >
                                I don't mind receiving occasional emails about
                                what's new on the Helsa platform.
                              </TextAlt>
                            </Box>
                            <Box pb={3}>
                              <Box flexBox alignItems="center">
                                <input
                                  type="checkbox"
                                  name="agreeTerms"
                                  checked={formikProps.values.agreeTerms}
                                  onChange={formikProps.handleChange}
                                  onBlur={formikProps.handleBlur}
                                />
                                <TextAlt
                                  m={0}
                                  pl={2}
                                  fontFamily="body"
                                  fontSize={11}
                                  color="muted"
                                >
                                  I agree to the{' '}
                                  <a
                                    href="https://helsahelps.com/TermsConditions"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    Helsa terms of service
                                  </a>
                                  .
                                </TextAlt>
                              </Box>
                              {!!formikProps.touched.agreeTerms &&
                                !!formikProps.errors.agreeTerms && (
                                  <CustomText
                                    fontFamily="body"
                                    fontSize={11}
                                    color="tomato"
                                    mb={2}
                                  >
                                    {formikProps.errors.agreeTerms}
                                  </CustomText>
                                )}
                            </Box>
                            <div>
                              <Button
                                className="default"
                                title="SUBMIT"
                                {...btnStyle}
                                onClick={formikProps.handleSubmit}
                              />
                            </div>
                          </>
                        );
                      }}
                    </Formik>
                  </Box>
                )}
              </TabPane>
              <TabPane tab="LOGIN" key="loginForm">
                <Heading content="Welcome Back" {...titleStyle} />
                <Text
                  content="Sign into your Helsa account to view your wellbeing indicator and match with our trusted therapists."
                  {...descriptionStyle}
                />
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  onSubmit={values => {
                    handleLogin(values);
                  }}
                >
                  {formikProps => (
                    <>
                      <Input
                        isMaterial
                        label="Email"
                        name="email"
                        value={formikProps.values.email}
                        onChange={formikProps.handleChange}
                      />
                      <Input
                        inputType="password"
                        isMaterial
                        label="Password"
                        name="password"
                        value={formikProps.values.password}
                        onChange={formikProps.handleChange}
                      />
                      <div>
                        {loginError && (
                          <CustomText
                            fontFamily="body"
                            fontSize={11}
                            color="tomato"
                            mb={3}
                            mt="-1rem"
                          >
                            {loginError}
                          </CustomText>
                        )}
                        <Button
                          className="default"
                          title="LOGIN"
                          {...btnStyle}
                          onClick={formikProps.handleSubmit}
                          disabled={formikProps.isSubmitting}
                        />
                      </div>
                      <Box pt={2}>
                        <Link
                          to="/forgot-password"
                          style={{
                            fontSize: theme.fontSizes[11],
                            color: 'blue',
                          }}
                        >
                          Forgot password?
                        </Link>
                      </Box>
                    </>
                  )}
                </Formik>
              </TabPane>
            </Tabs>
          </Box>
        </Box>
      </Box>
    </LoginModalWrapper>
  );
};

// LoginModal style props
AuthModal.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  logoStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  hintTextStyle: PropTypes.object,
  contentWrapper: PropTypes.object,
  descriptionStyle: PropTypes.object,
  googleButtonStyle: PropTypes.object,
};

// LoginModal default style
AuthModal.defaultProps = {
  // Team member row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Team member col default style
  col: {
    width: [1, 1 / 2],
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    height: 'auto',
    ml: '15px',
  },
  // Title default style
  titleStyle: {
    fontSize: ['22px', '36px', '50px'],
    fontWeight: '400',
    color: '#20201D',
    letterSpacing: '-0.025em',
    mt: '35px',
    mb: '10px',
  },
  // Description default style
  descriptionStyle: {
    color: 'rgba(52, 61, 72, 0.8)',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '-0.025em',
    mb: '23px',
    ml: '1px',
  },
  // Content wrapper style
  contentWrapper: {
    pt: ['32px', '56px'],
    pl: ['17px', '32px', '38px', '40px', '56px'],
    pr: '32px',
    pb: ['32px', '56px'],
  },
  // Default button style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  // Outline button outline style
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: 'rgb(26, 115, 232)',
  },
  // Google button style
  googleButtonStyle: {
    bg: '#ffffff',
    color: '#343D48',
  },
};

export default AuthModal;
