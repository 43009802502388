import React, { useContext, useEffect, useState } from 'react';
import { Link, navigate } from "gatsby";
import PropTypes from 'prop-types';
import NavbarWrapper from '../../../reusecore/src/elements/Navbar';
import Drawer from '../../../reusecore/src/elements/Drawer';
import Button from '../../../reusecore/src/elements/Button';
import Logo from '../../../reusecore/src/elements/UI/Logo';
import HamburgMenu from '../../../superprops/src/components/HamburgMenu';
import ScrollSpyMenu from '../../../superprops/src/components/ScrollSpyMenu';
import { Container } from './navbar.style';
import { openModal, closeModal } from '@redq/reuse-modal';
import LogoImage from '../../../assets/image/helsa-pride-white.png';
import { DrawerContext } from '../../../superprops/src/contexts/DrawerContext';
import AuthModal from '../../../components/AuthModal';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth } from '../../../services/firebase';
import firebase from 'firebase';
import { useViewport } from '../../../utils/UseViewport';
import close from '../../../assets/image/multiply.png';
import { CloseIcon } from '@redq/reuse-modal/lib/closeSvg';

export const handleLoginModal = () => {
  openModal({
    config: {
      className: 'login-modal',
      disableDragging: true,
      width: '100%',
      height: '100%',
      animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
      animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
      transition: {
        mass: 1,
        tension: 180,
        friction: 26,
      },
    },
    component: AuthModal,
    componentProps: {},
    closeComponent: CloseModalButton,
    closeOnClickOutside: false,
  });
};

const closeIcon = () => <img src={close} alt="close" />;

const logInOutItems = [
  {
    path: '#',
    label: 'Log Out',
    auth: true,
    onClick: async () => {
      await firebase.auth().signOut();
      window.location.reload();
    },
    currentClass: false,
  },
  {
    path: '#',
    label: 'Log In',
    auth: false,
    onClick: handleLoginModal,
    currentClass: false,
  },
];

// Default close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

export default function Navbar() {
  const [user, loading, error] = useAuthState(getFirebaseAuth());
  const [currentPath, setCurrentPath] = useState('/');
  const { width } = useViewport();
  const { state, dispatch } = useContext(DrawerContext);

  const NavbarLink = menuItem => {
    const linkClass = currentPath === menuItem.path ? 'is-current' : null;
    return menuItem.isExternal ? (
      <div key={menuItem.label}>
        <a
          href={menuItem.path}
          rel="noopener noreferrer"
          target={menuItem.newWindow ? '_blank' : '_self'}
        >
          {menuItem.label}
        </a>
      </div>
    ) : (
      <div key={menuItem.label}>
        <Link
          to={menuItem.path}
          onClick={menuItem.onClick || null}
          className={menuItem.currentClass ? linkClass : false}
        >
          {menuItem.label}
        </Link>
      </div>
    );
  };

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };
  const MENU_ITEMS = [
    {
      path: 'https://www.helsahelps.com',
      isExternal: true,
      label: 'Home',
      auth: false,
      currentClass: true,
    },
    {
      path: '/about',
      label: 'About',
      auth: false,
      currentClass: true,
    },
    {
      path: '/match',
      label: 'Therapist Match',
      auth: true,
      currentClass: true,
    },
    {
      path: 'https://www.helsahelps.com/training',
      label: 'Training',
      auth: false,
      isExternal: true,
      currentClass: false,
    },
    {
      path: 'https://support.helsahelps.com',
      label: 'Support',
      auth: false,
      isExternal: true,
      currentClass: false,
    },
    {
      path: 'https://www.helsahelps.com/research',
      label: 'Research',
      auth: false,
      isExternal: true,
      currentClass: false,
    },
    {
      path: '/explore',
      label: 'Articles',
      auth: false,
      currentClass: true,
    },
    {
      path: 'https://www.empathyvr.io/',
      label: 'Empathy VR',
      isExternal: true,
      auth: false,
      currentClass: true,
      newWindow: true,
    },
    {
      path: user ? '/directory' : '#',
      label: 'Partner Therapists',
      auth: false,
      onClick: () => (user ? navigate('/directory') : handleLoginModal()),
      currentClass: !!user,
    },
    {
      path: '/user',
      label: 'My Dashboard',
      auth: true,
      currentClass: true,
    },
  ];

  useEffect(() => setCurrentPath(window.location.pathname), []);

  return (
    <NavbarWrapper
      navbarStyle={{
        minHeight: '74px',
        position: 'relative',
        zIndex: 999,
      }}
    >
      <Container>
        <Logo
          href="https://www.helsahelps.com"
          logoSrc={LogoImage}
          title="Agency"
          logoStyle={{
            width: ['100px', '140px'],
          }}
        />
        {width > 1024 ? (
          <div className="reusecore__navbar__links">
            {MENU_ITEMS.map((item, i) =>
              item.auth ? (user ? NavbarLink(item) : null) : NavbarLink(item)
            )}
            {user ? NavbarLink(logInOutItems[0]) : NavbarLink(logInOutItems[1])}
          </div>
        ) : (
          <Drawer
            width="100vw"
            placement="right"
            drawerHandler={<HamburgMenu />}
            open={state.isOpen}
            toggleHandler={toggleHandler}
            closeButton={<CloseIcon />}
          >
            <ScrollSpyMenu
              menuItems={MENU_ITEMS}
              offset={-100}
              logInOutItems={logInOutItems}
            />
          </Drawer>
        )}
      </Container>
    </NavbarWrapper>
  );
}

// Navbar style props
Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  wrapperStyle2: PropTypes.object,
  contrast: PropTypes.bool,
  barsOnly: PropTypes.bool,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
  },
  logoStyle: {
    width: ['100px', '140px'],
  },
  buttonStyle: {
    minHeight: '70px',
    color: '#fff',
  },
};
