import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import '../../styles/bootstrap.min.css';
import '../../styles/shards-pro.css';
import 'react-awesome-button/dist/styles.css';
import '../../styles/awesome-button.scss';
import '../../styles/progress-styles.css';
import '../../styles/burger-menu.css';
import '../../styles/spinkit.css';
import '@redq/reuse-modal/es/index.css';
import '../../superprops/src/assets/css/flaticon.css';
import Footer from '../../containers/App/Footer';
import Helmet from 'react-helmet';
import { theme } from '../../theme';
import Navbar from '../../containers/App/Navbar';
import { GlobalStyle, AppWrapper } from '../../containers/App/app.style';
import Sticky from 'react-stickynode';
import { DrawerProvider } from '../../superprops/src/contexts/DrawerContext';
import { Modal } from '@redq/reuse-modal';
import favIcon from '../../assets/image/favicon.png';

const SiteRoot = styled.div<{}>`
  height: 100vh;
  width: 100%;
`;

export interface LayoutProps {
  fixedNav?: boolean;
  transparentNav?: boolean;
  bg?: string;
  contrast?: boolean;
  barsOnly?: boolean;
}

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  contrast,
  barsOnly,
  transparentNav = false,
}): JSX.Element => {
  return (
    <SiteRoot id="site-root">
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>Helsa</title>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
          <link rel="icon" type="image/png" href={favIcon} sizes="16x16" />
          <link
            href="//db.onlinewebfonts.com/c/4a4fee972680df7928438c04f33e9cca?family=ProximaNovaW01-Regular"
            rel="stylesheet"
            type="text/css"
          />
        </Helmet>
        <Modal />
        <GlobalStyle />
        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar contrast={contrast} barsOnly={barsOnly} />
            </DrawerProvider>
          </Sticky>
          {children}
          <Footer />
        </AppWrapper>
      </ThemeProvider>
    </SiteRoot>
  );
};

export default Layout;
