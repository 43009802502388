import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Box from '../../../reusecore/src/elements/Box';
import Text from '../../../reusecore/src/elements/Text';
import Heading from '../../../reusecore/src/elements/Heading';
import Logo from '../../../reusecore/src/elements/UI/Logo';
import Container from '../../../superprops/src/components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';
import LogoImage from '../../../assets/image/helsa-pride-white.png';
import { HelsaFooterQuery } from '../../../graphql-types';
import { theme } from '../../../theme';
import { Row, Container as BSContainer, Col } from 'reactstrap';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
  copyrightMenu,
  copyright,
}) => {
  const data = useStaticQuery(graphql`
    query {
      generalPages: allContentfulPage(filter: { pageType: { eq: "General" } }) {
        edges {
          node {
            title
            slug
            pageType
          }
        }
      }
      legalPages: allContentfulPage(filter: { pageType: { eq: "Legal" } }) {
        edges {
          node {
            title
            slug
            pageType
          }
        }
      }
      communityPages: allContentfulPage(
        filter: { pageType: { eq: "Community" } }
      ) {
        edges {
          node {
            title
            slug
            pageType
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container>
        <Box className="row" {...row}>
          <Box {...colOne}>
            <BSContainer>
              <Row style={{ paddingBottom: theme.space[4] }}>
                {[
                  data.generalPages.edges,
                  data.legalPages.edges,
                  data.communityPages.edges,
                ].map((widget, i) => (
                  <Col key={i} xs={12} sm={4} style={{ display: 'flex' }}>
                    <Box className="col" {...col} key={widget}>
                      <Heading
                        content={widget[0].node.pageType}
                        {...titleStyle}
                      />
                      <List>
                        {widget.map(item => (
                          <ListItem key={`list__item-${item.node.slug}`}>
                            <Link
                              to={`/${item.node.slug}`}
                              className="ListItem"
                            >
                              {item.node.title}
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Col>
                ))}
              </Row>
            </BSContainer>
          </Box>
          <Box {...colTwo} className="copyrightClass">
            <Logo logoSrc={LogoImage} title="App" logoStyle={logoStyle} />
          </Box>
          {/* End of footer List column */}
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colTwo: {
    mt: [0, '13px'],
    mb: ['0px', 0],
    pl: ['15px', 0],
    pt: ['35px', '55px'],
    pr: ['15px', '15px', 0],
    borderTop: '1px solid',
    borderColor: 'rgba(255,255,255,0.5)',
    flexBox: true,
    flexWrap: 'wrap',
    width: ['100%'],
  },
  // Footer col two style
  colOne: {
    width: ['100%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%', '25%'],
    pl: ['15px', '0px'],
    pr: ['15px', '0px'],
  },
  // widget title default style
  titleStyle: {
    mb: 0,
    color: 'white',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: theme.letterSpacings.normal,
  },
  // Default logo size
  logoStyle: {
    width: 'auto',
    maxWidth: '100px',
    mb: ['15px', 0],
  },
  // widget text default style
  textStyle: {
    color: 'white',
    fontSize: '14px',
    mb: '10px',
    mr: '30px',
  },
  copyrightMenu: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: [0, '40px'],
    mt: '3px',
    fontWeight: '500',
    justifyContent: 'center',
    alignItems: 'center',
    mb: ['15px', 0],
  },
  copyright: {
    ml: [0, 0, 0, 'auto', 'auto'],
    color: '#20201d',
    fontSize: '14px',
    mb: '10px',
    mt: '3px',
    fontWeight: '500',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default Footer;
