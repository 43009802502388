import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import React, { Fragment } from 'react';
import Text from '../reusecore/src/elements/Text/text';
import { theme } from '../theme';
import styled, { css } from 'styled-components';
import Image from '../reusecore/src/elements/Image';

const noInitial = css`
  & > *:nth-of-type(1):first-letter {
    font-size: inherit !important;
    height: inherit !important;
    line-height: inherit !important;
    padding: 0 !important;
  }
`;

const NoInitialText = styled(Text)`
  ${noInitial}
`;

const ParagraphText = styled(Text)``;

const BlockQuoteText = styled(Text)`
  line-height: 100%;
  position: relative;
  font-family: Proxima Nova, sans-serif;
  font-weight: ${theme.fontWeights[3]};
  margin: 5em auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 50ch;
  align-self: center;
  text-align: center;
  & > p {
    font-weight: ${theme.fontWeights[3]};
    font-size: ${theme.fontSizes[3]};
    margin: 0;
  }

  &:before {
    content: '\\201C';
    position: absolute;
    top: -10px;
    left: 0;
    font-family: Proxima Nova, sans-serif;
    font-size: 120px;
    font-style: normal;
    color: ${theme.colors.secondary};
  }
  &:after {
    content: '\\201D';
    position: absolute;
    bottom: -50px;
    right: 0;
    font-family: Proxima Nova, sans-serif;
    font-size: 120px;
    font-style: normal;
    color: ${theme.colors.secondary};
  }

  ${noInitial}
`;

interface EmbeddedIframeProps {
  iframeWidth: number;
  ratioPadding: number;
}

const GenericEmbed = styled.div<EmbeddedIframeProps>`
  display: inline-block;
  width: ${props => props.iframeWidth}px;
  max-width: 85vw;
  & > div {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: ${props => props.ratioPadding}%;
  }
  & iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const GettyEmbed = styled(GenericEmbed)`
  background-color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  color: #a7a7a7;
  & a {
    color: #a7a7a7;
    text-decoration: none;
    font-weight: normal !important;
    border: none;
    display: inline-block;
  }
`;

export const articleRichTextOptions: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const locale = 'en-US';
      const {
        contentSource,
        iframeContentSource,
        linkToContent,
        name,
        width,
        height,
      } = node.data.target.fields;
      let WrapperComponent;
      let linkText;

      switch (contentSource[locale]) {
        case 'Getty':
          WrapperComponent = GettyEmbed;
          linkText = 'Embed from Getty Images';
          break;
        default:
          WrapperComponent = GenericEmbed;
          linkText = 'Embedded Content Source';
      }
      const paddingPercent = (height['en-US'] / width['en-US']) * 100;
      return (
        <WrapperComponent
          iframeWidth={width['en-US']}
          ratioPadding={paddingPercent}
        >
          <a
            href={linkToContent[locale]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkText}
          </a>
          <div>
            <iframe
              title={name[locale]}
              src={iframeContentSource[locale]}
              width="100%"
              height="100%"
            />
          </div>
        </WrapperComponent>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <Image
          className="img-fluid"
          src={node.data.target.fields.file['en-US'].url}
          alt="alt"
          mb={1}
          key={node.data.target.fields.file['en-US'].url}
        />
      );
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <Text
        as="h2"
        fontFamily="heading"
        fontSize={3}
        fontWeight={4}
        m={0}
        mb={1}
        textAlign="left"
        lineHeight="title"
        color="black"
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Text
        as="h2"
        fontFamily="heading"
        fontSize={2}
        fontWeight={3}
        m={0}
        mb={1}
        textAlign="left"
        lineHeight="title"
        color="black"
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Text
        as="h2"
        fontFamily="heading"
        fontSize={1}
        fontWeight={2}
        m={0}
        mb={1}
        textAlign="left"
        lineHeight="title"
        color="black"
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Text
        as="h2"
        fontFamily="body"
        fontSize={3}
        fontWeight={4}
        m={0}
        mb={1}
        textAlign="left"
        lineHeight="title"
        color="black"
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Text
        as="h2"
        fontFamily="body"
        fontSize={3}
        fontWeight={3}
        mb={2}
        textAlign="left"
        lineHeight="title"
        color="black"
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Text
        as="h2"
        fontFamily="body"
        fontSize={0}
        fontWeight={1}
        mb={2}
        textAlign="left"
        lineHeight="title"
      >
        {children}
      </Text>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <ParagraphText
        fontFamily="body"
        fontSize="18px"
        fontWeight={400}
        letterSpacing="normal"
        mb={4}
        textAlign="left"
        lineHeight="copy"
      >
        {children}
      </ParagraphText>
    ),
    [BLOCKS.QUOTE]: (node, children) => (
      <BlockQuoteText>{children}</BlockQuoteText>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      //@ts-ignore
      <NoInitialText
        as="li"
        fontFamily="body"
        fontSize="1rem"
        fontWeight={400}
        mb={3}
        textAlign="left"
        lineHeight={undefined}
      >
        {children}
      </NoInitialText>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: text => (
      <span key={`${text}-key`} style={{ fontWeight: 700 }}>
        {text}
      </span>
    ),
    [MARKS.ITALIC]: text => <i key={`${text}-key`}>{text}</i>,
  },
  renderText: text =>
    text.split('\n').map((text, i) => (
      <Fragment key={text + i}>
        {i > 0 && <br />}
        {text}
      </Fragment>
    )),
};
