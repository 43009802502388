import colors from './colors';
import { darken, lighten } from 'polished';

export const theme = {
  breakpoints: ['576px', '768px', '992px', '1200px'],
  space: [
    // Important to have zero for responsive cases.
    '0',
    '0.25rem',
    '0.5rem',
    '1rem',
    '2rem',
    '3rem',
    '4rem',
    '6rem',
    '8rem',
    '12rem',
    '16rem',
  ],
  sizes: [
    // Important to have zero for responsive cases.
    '0',
    '0.25rem',
    '0.5rem',
    '1rem',
    '2rem',
    '3rem',
    '4rem',
    '6rem',
    '8rem',
    '12rem',
    '16rem',
    '20rem',
    '25rem',
  ],
  fontSizes: [
    '1rem',
    '1.25rem',
    '1.4rem',
    '1.6rem',
    '2rem',
    '2.25rem',
    '2.5rem',
    '3rem',
    '4rem',
    '6rem',
    '8rem',
    '0.75rem',
    '0.5rem',
  ],
  fontWeights: [300, 400, 500, 600, 700, 800, 900],
  height: ['12px', '24px', '36px', '48px'],
  width: ['12px', '24px', '36px', '48px'],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  fonts: {
    // Don't double do the quotes.
    body: 'proxima-nova, sans-serif',
    heading: 'Montserrat, sans-serif',
  },
  borders: [0, '1px solid', '2px solid', '4px solid'],
  radii: [
    '0px',
    '3px',
    '5px',
    '10px',
    '15px',
    '20px',
    '25px',
    '50px',
    '60px',
    '50%',
  ],
  shadows: {
    card: '2px 4px 20px rgba(196, 196, 196, 0.25)',
  },
  colors,
  colorStyles: {
    primary: {
      color: colors.white,
      backgroundColor: colors.primary,
      borderColor: colors.primary,
      '&:hover': {
        color: colors.white,
        borderColor: colors.primary,
        boxShadow: `0px 9px 20px -5px ${colors.greyShadow}`,
      },
    },
    secondary: {
      color: colors.white,
      backgroundColor: colors.secondary,
      borderColor: colors.secondary,
      '&:hover': {
        color: colors.white,
        borderColor: colors.secondary,
        boxShadow: `0px 9px 20px -5px ${colors.greyShadow}`,
        backgroundColor: `${darken(0.1, colors.secondary)} !important`,
      },
    },
    dark: {
      color: colors.white,
      backgroundColor: colors.black,
      borderColor: colors.black,
      '&:hover': {
        color: colors.white,
        borderColor: colors.black,
        boxShadow: `0px 9px 20px -5px ${colors.greyShadow}`,
        backgroundColor: `${lighten(0.15, colors.black)} !important`,
      },
    },
    warning: {
      color: colors.yellow,
      borderColor: colors.yellow,
      '&:hover': {
        color: colors.yellowHover,
        borderColor: colors.yellowHover,
      },
    },
    error: {
      color: colors.secondaryHover,
      borderColor: colors.secondaryHover,
      '&:hover': {
        color: colors.secondary,
        borderColor: colors.secondary,
      },
    },
    primaryWithBg: {
      color: colors.white,
      backgroundColor: colors.primary,
      borderColor: colors.primary,
      '&:hover': {
        backgroundColor: colors.primaryHover,
        borderColor: colors.primaryHover,
      },
    },
    secondaryWithBg: {
      color: colors.white,
      backgroundColor: colors.secondary,
      borderColor: colors.secondary,
      '&:hover': {
        backgroundColor: colors.secondaryHover,
        borderColor: colors.secondaryHover,
      },
    },
    warningWithBg: {
      color: colors.white,
      backgroundColor: colors.yellow,
      borderColor: colors.yellow,
      '&:hover': {
        backgroundColor: colors.yellowHover,
        borderColor: colors.yellowHover,
      },
    },
    errorWithBg: {
      color: colors.white,
      backgroundColor: colors.secondaryHover,
      borderColor: colors.secondaryHover,
      '&:hover': {
        backgroundColor: colors.secondary,
        borderColor: colors.secondary,
      },
    },
    whitePrimary: {
      color: colors.primary,
      backgroundColor: colors.white,
      borderColor: colors.white,
      '&:hover': {
        backgroundColor: `${darken(0.1, colors.white)} !important`,
        boxShadow: `0px 9px 20px -5px ${colors.greyShadow}`,
      },
    },
    whiteSecondary: {
      color: colors.secondary,
      backgroundColor: colors.white,
      borderColor: colors.white,
      '&:hover': {
        backgroundColor: `${darken(0.1, colors.white)} !important`,
        boxShadow: `0px 9px 20px -5px ${colors.greyShadow}`,
      },
    },
  },
  buttonStyles: {
    textButton: {
      border: 0,
      color: colors.primary,
      padding: 0,
      height: 'auto',
      backgroundColor: colors.transparent,
    },
    outlined: {
      borderWidth: '1px',
      borderStyle: 'solid',
      backgroundColor: colors.transparent,
    },
    fab: {
      border: '0',
      width: '40px',
      height: '40px',
      padding: 0,
      borderRadius: '50%',
      justifyContent: 'center',
      'span.btn-icon': {
        paddingLeft: 0,
      },
    },
    extendedFab: {
      border: '0',
      minWidth: '50px',
      height: '40px',
      borderRadius: '50px',
      justifyContent: 'center',
    },
  },
};
