import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Scrollspy from 'react-scrollspy';
import { Link } from 'gatsby';

import { DrawerContext } from '../../contexts/DrawerContext';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth } from '../../../../services/firebase';

const ScrollSpyMenu = ({ className, menuItems, logInOutItems, ...props }) => {
  const { dispatch } = useContext(DrawerContext);
  const [user, loading, error] = useAuthState(getFirebaseAuth());
  const [currentPath, setCurrentPath] = useState('/');

  // empty array for scrollspy items
  const scrollItems = [];

  // convert menu path to scrollspy items
  menuItems.forEach(item => {
    scrollItems.push(item.path.slice(1));
  });

  // Add all classs to an array
  const addAllClasses = ['scrollspy__menu'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  // Close drawer when click on menu item
  const toggleDrawer = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const NavbarLink = menuItem => {
    const linkClass = currentPath === menuItem.path ? 'is-current' : null;
    return menuItem.isExternal ? (
      <div>
        <a href={menuItem.path} rel={'noopener noreferrer'}>
          {menuItem.label}
        </a>
      </div>
    ) : (
      <div>
        <Link
          to={menuItem.path}
          className={menuItem.currentClass ? linkClass : false}
          onClick={() => {
            toggleDrawer();
            return menuItem.onClick() || null;
          }}
        >
          {menuItem.label}
        </Link>
      </div>
    );
  };

  useEffect(() => setCurrentPath(window.location.pathname), []);

  return (
    <Scrollspy
      items={scrollItems}
      className={addAllClasses.join(' ')}
      {...props}
    >
      {menuItems.map((menu, index) =>
        menu.auth ? (
          user ? (
            <li key={`menu-item-${index}`}>{NavbarLink(menu)}</li>
          ) : null
        ) : (
          <li key={`menu-item-${index}`}>{NavbarLink(menu)}</li>
        )
      )}
      {user ? (
        <li>{NavbarLink(logInOutItems[0])}</li>
      ) : (
        <li>{NavbarLink(logInOutItems[1])}</li>
      )}
    </Scrollspy>
  );
};

ScrollSpyMenu.propTypes = {
  /** className of the ScrollSpyMenu. */
  className: PropTypes.string,

  /** menuItems is an array of object prop which contain your menu
   * data.
   */
  menuItems: PropTypes.array.isRequired,

  /** Class name that apply to the navigation element paired with the content element in viewport. */
  currentClassName: PropTypes.string,

  /** Class name that apply to the navigation elements that have been scrolled past [optional]. */
  scrolledPastClassName: PropTypes.string,

  /** HTML tag for Scrollspy component if you want to use other than <ul/> [optional]. */
  componentTag: PropTypes.string,

  /** Style attribute to be passed to the generated <ul/> element [optional]. */
  style: PropTypes.object,

  /** Offset value that adjusts to determine the elements are in the viewport [optional]. */
  offset: PropTypes.number,

  /** Name of the element of scrollable container that can be used with querySelector [optional]. */
  rootEl: PropTypes.string,

  /**
   * Function to be executed when the active item has been updated [optional].
   */
  onUpdate: PropTypes.func,
};

ScrollSpyMenu.defaultProps = {
  componentTag: 'ul',
  currentClassName: 'is-current',
};

export default ScrollSpyMenu;
