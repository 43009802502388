import React, { useState } from 'react';
import InputField, { EyeButton } from './input.style';

export interface InputProps {
  className?: any;
  label?: any;
  value?: any;
  isMaterial?: any;
  isDirectoryInput?: boolean;
  passwordShowHide?: any;
  inputType?: any;
  icon?: any;
  iconPosition?: any;
  onBlur?: any;
  onFocus?: any;
  onChange?: any;
  name?: any;
}

const Input: React.FunctionComponent<InputProps> = ({
  label,
  value,
  onBlur,
  onFocus,
  onChange,
  inputType,
  isMaterial,
  isDirectoryInput,
  icon,
  iconPosition,
  passwordShowHide,
  className,
  name,
  ...props
}) => {
  // use toggle hooks
  const [state, setState] = useState({
    toggle: false,
    focus: false,
    value: value,
  });

  // toggle function
  const handleToggle = () => {
    setState({
      ...state,
      toggle: !state.toggle,
    });
  };

  // add focus class
  const handleOnFocus = event => {
    setState({
      ...state,
      focus: true,
    });
    onFocus(event);
  };

  // remove focus class
  const handleOnBlur = event => {
    setState({
      ...state,
      focus: false,
    });
    onBlur(event);
  };

  // get input focus class
  const getInputFocusClass = () => {
    if (state.focus === true || value) {
      return 'is-focus';
    } else {
      return '';
    }
  };

  // init variable
  let inputElement, htmlFor;

  // Add all classs to an array
  const addAllClasses = ['reusecore__input'];

  // Add is-material class
  if (isMaterial) {
    addAllClasses.push('is-material');
  }

  if (isDirectoryInput) {
    addAllClasses.push('is-directory-input');
  }

  // Add icon position class if input element has icon
  if (icon && iconPosition) {
    addAllClasses.push(`icon-${iconPosition}`);
  }

  // Add new class
  if (className) {
    addAllClasses.push(className);
  }

  // if lable is not empty
  if (label) {
    htmlFor = label.replace(/\s+/g, '_').toLowerCase();
  }

  // Label position
  const LabelPosition = isMaterial === true ? 'bottom' : 'top';

  // Label field
  const LabelField = label && <label htmlFor={htmlFor}>{label}</label>;

  // Input type check
  switch (inputType) {
    case 'textarea':
      inputElement = (
        <textarea
          {...props}
          id={htmlFor}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
        />
      );
      break;

    case 'password':
      inputElement = (
        <div className="field-wrapper">
          <input
            {...props}
            id={htmlFor}
            name={name}
            type="password"
            value={value}
            onChange={onChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
          />
          {passwordShowHide && (
            <EyeButton
              onClick={handleToggle}
              className={state.toggle ? 'eye' : 'eye-closed'}
            >
              <span />
            </EyeButton>
          )}
        </div>
      );
      break;

    default:
      inputElement = (
        <div className="field-wrapper">
          <input
            {...props}
            id={htmlFor}
            name={name}
            type={inputType}
            value={value}
            onChange={onChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
          />
          {icon && <span className="input-icon">{icon}</span>}
        </div>
      );
  }

  return (
    <InputField
      className={`${addAllClasses.join(' ')} ${getInputFocusClass()}`}
    >
      {LabelPosition === 'top' && LabelField}
      {inputElement}
      {isMaterial && <span className="highlight" />}
      {LabelPosition === 'bottom' && LabelField}
    </InputField>
  );
};

/** Input default type. */
Input.defaultProps = {
  inputType: 'text',
  isMaterial: false,
  isDirectoryInput: false,
  iconPosition: 'left',
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
};

export default Input;
