import React from 'react';
import ReactSelect, { CommonProps } from 'react-select';
import PropTypes from 'prop-types';
import SelectStyle from './select.style';
import { useField } from 'formik';

export interface SelectProps {
  className?: any;

  /** labelText of the select field */
  labelText?: any;

  /** Set label position of the select field. By default it's top */
  labelPosition?: any;

  name: any;
  options: any;
  placeholder: string;
}

const Select: React.FunctionComponent<SelectProps> = ({
  className,
  labelText,
  labelPosition,
  placeholder,
  ...props
}) => {
  const { name, options } = props;
  const [field, , helpers] = useField<string>(name);

  // Add all classes to an array
  const addAllClasses = ['reusecore__select'];

  // Add label position class
  if (labelPosition) {
    addAllClasses.push(`label_${labelPosition}`);
  }

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  const LabelField = labelText && (
    <span className="reusecore__field-label">{labelText}</span>
  );

  const position = labelPosition || 'top';

  return (
    <SelectStyle className={addAllClasses.join(' ')}>
      {position === 'left' || position === 'right' || position === 'top'
        ? LabelField
        : ''}

      <ReactSelect
        className="select-field__wrapper"
        classNamePrefix="select"
        options={options}
        name={field.name}
        value={
          options ? options.find(option => option.value === field.value) : ''
        }
        onChange={(option): void => helpers.setValue(option.value)}
        onBlur={() => {
          helpers.setTouched(true);
        }}
        placeholder={placeholder}
        maxMenuHeight={150}
      />
      {position === 'bottom' && LabelField}
    </SelectStyle>
  );
};

export default Select;
