import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ButtonStyle from "./button.style";
import Loader from "../Loader";

const Button = (props) => {
  // Add all classs to an array
  const addAllClasses = ["reusecore__button"];

  // isLoading prop checking
  if (props.isLoading) {
    addAllClasses.push("is-loading");
  }

  // isMaterial prop checking
  if (props.isMaterial) {
    addAllClasses.push("is-material");
  }

  // className prop checking
  if (props.className) {
    addAllClasses.push(props.className);
  }

  // Checking button loading state
  const buttonIcon =
    props.isLoading !== false ? (
      <Fragment>
        {props.loader ? props.loader : <Loader loaderColor={props.loaderColor || "#30C56D"}/>}
      </Fragment>
    ) : (
      props.icon && <span className="btn-icon">{props.icon}</span>
    );

  // set icon position
  const position = props.iconPosition || "right";

  return (
    <ButtonStyle
      type={props.type}
      className={addAllClasses.join(" ")}
      icon={props.icon}
      disabled={props.disabled}
      icon-position={position}
      onClick={props.onClick}
      {...props}
    >
      {position === "left" && buttonIcon}
      {props.children}
      {props.title && <span className="btn-text">{props.title}</span>}
      {position === "right" && buttonIcon}
    </ButtonStyle>
  );
};


Button.defaultProps = {
  disabled: false,
  isMaterial: false,
  isLoading: false,
  type: "button"
};

export default Button;
