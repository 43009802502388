import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
// @ts-ignore
import { AwesomeButton } from 'react-awesome-button';

export interface MainButtonProps extends HTMLAttributes<HTMLButtonElement> {
  type: string;
}

export const MainButton: React.FunctionComponent<MainButtonProps> = ({
  children,
  onClick,
  type,
  ...rest
}): JSX.Element => {
  return (
    <AwesomeButton {...rest} onPress={onClick} type={type}>
      {children}
    </AwesomeButton>
  );
};
