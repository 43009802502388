import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyAr9RGyJa-XsSFohSBo9dH5XZBnPjXCrVs',
  authDomain: 'helsahelps.firebaseapp.com',
  databaseURL: 'https://helsahelps.firebaseio.com',
  projectId: 'helsahelps',
  storageBucket: 'helsahelps.appspot.com',
  messagingSenderId: '392199569123',
  appId: '1:392199569123:web:81f6f004e1b5889338cfd6',
};

let instance;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(config);
    return instance;
  }
  return null;
}

export function getFirebaseAuth() {
  const instance = getFirebase();
  return instance ? firebase.auth() : null;
}

export function getFirebaseFirestore() {
  const instance = getFirebase();
  return instance ? firebase.firestore() : null;
}
