const colors = {
  transparent: 'transparent',
  labelColor: '#767676',
  inactiveField: '#f2f2f2',
  inactiveButton: '#b7dbdd',
  inactiveIcon: '#EBEBEB',
  primaryHover: '#006b70',
  secondaryHover: '#FF282F',
  yellow: '#fdb32a',
  yellowHover: '#F29E02',
  borderColor: '#dadada',
  black: '#000000',
  white: '#ffffff',
  primary: '#ff8f40',
  secondary: '#3c8271',
  headingColor: '#0f2137',
  quoteText: '#343d48',
  textColor: '#333',
  linkColor: '#ff8f40',
  blackTrans: 'rgba(22,22,22,.65)',
  whiteTrans: 'rgba(255,255,255,.75)',
  sand: '#f2f2f2',
  lightGrey: 'gainsboro',
  anxiety: '#946395',
  depression: '#4C86A8',
  minorityStress: '#2ea892',
  stress: '#FFA6A6',
  greyShadow: 'rgba(122, 122, 122, .55)',
  wellbeingTrans: 'rgba(255,255,255,.5)',
  cardBlue: '#4fb8c1',
  muted: '#868e96',
  blobGreen: '#9bc0b7',
  hr: 'rgb(219, 227, 231)',
};

export default colors;
